<template>
  <b-card>
    <b-row>
      <b-col md="4">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Марка Авто" label-for="car_make">
                <b-form-select
                  id="car_make"
                  v-model="inputVal.car_mark_id"
                  :options="carManufacturers"
                  placeholder="Выберите"
                  text-field="name"
                  value-field="id"
                  @input="getCarModels"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Модель" label-for="car_model">
                <b-form-select
                  id="model"
                  v-model="inputVal.car"
                  :options="carModels"
                  placeholder="Выберите"
                  text-field="name"
                  value-field="id"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Тип двигателя" label-for="engine_type">
                <b-form-select
                  id="engine_type"
                  v-model="inputVal.engine_type"
                  :options="engines"
                  placeholder="Выберите"
                  text-field="display_name"
                  value-field="value"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Тип коробки" label-for="gear_type">
                <b-form-select
                  id="gear_type"
                  v-model="inputVal.gear_type"
                  :options="gears"
                  placeholder="Выберите"
                  text-field="display_name"
                  value-field="value"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Оценочная стоимость"
                label-for="market_price"
              >
                <b-form-input
                  id="market_price"
                  v-model="inputVal.market_price"
                  placeholder="0 BYN"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Год автомобиля" label-for="year">
                <b-form-input
                  id="year"
                  v-model="inputVal.year"
                  placeholder="2020"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="VIN" label-for="vin">
                <b-form-input
                  id="vin"
                  v-model="inputVal.vin"
                  placeholder="1FMZU34E2XCB34385"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Регистрационный знак"
                label-for="license_plate"
              >
                <b-form-input
                  id="license_plate"
                  v-model="inputVal.license_plate"
                  placeholder="0000 AA-7"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Пробег, км" label-for="mileage">
                <b-form-input
                  id="mileage"
                  v-model="inputVal.mileage"
                  placeholder="1500"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                class="mr-1"
                type="submit"
                variant="primary"
                @click="updateApplication"
              >
                Обновить
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col md="8">
        <div class="d-flex justify-content-end mb-2">
          <b-form-file
            id="carFileUpload"
            v-model="carPhoto"
            class="d-none"
            plain
            @input="uploadPhoto"
          />
          <b-button variant="primary" @click="showUpload"> Загрузить</b-button>
        </div>
        <swiper
          ref="swiperAuto"
          :auto-destroy="true"
          :auto-update="true"
          :options="swiperOptions"
          class="text-center"
          instance-name="swiperAuto"
        >
          <swiper-slide v-for="(photo, index) in carPhotos" :key="photo.id">
            <b-button
              size="24"
              variant="primary"
              class="position-absolute zindex-1"
              :style="{ left: '10px' }"
              @click="rotatePhoto(index, 90)"
            >
              <feather-icon icon="RotateCwIcon" size="24" />
            </b-button>
            <b-img
              :src="photo.photo"
              :style="getImageStyles(photo.rotation)"
              fluid
              width="500"
            />
            <div
              :class="{
                'swiper-button-disabled': index === carPhotos.length - 1,
              }"
              class="swiper-button-next"
              @click="nextSlide"
            ></div>
            <div
              :class="{ 'swiper-button-disabled': index === 0 }"
              class="swiper-button-prev"
              @click="prevSlide"
            ></div>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination2"></div>
        </swiper>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'ApplicationCarCard',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ['value'],
  data() {
    return {
      carPhotos: [],
      carPhoto: null,
      carManufacturers: [],
      carModels: [],
      engines: [],
      gears: [],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination2',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    inputVal() {
      this.getCarPhotos()
      if (this.inputVal.car_mark_id) {
        this.getCarModels()
      }
    },
  },
  created() {
    if (this.inputVal.id) {
      this.getCarPhotos()
    }
    this.getCarMarks()
    this.getCarOptions()
    if (this.inputVal.car_mark_id) {
      this.getCarModels()
    }
  },
  methods: {
    async getCarOptions() {
      const res = await this.$http.options('applications/create')
      this.engines = res.data.actions.POST.engine_type.choices
      this.gears = res.data.actions.POST.gear_type.choices
    },
    async getCarMarks() {
      const res = await this.$http.get('admin/car-manufacturers')
      this.carManufacturers = res.data.results
    },
    async getCarModels() {
      const res = await this.$http.get(
        `admin/car-models/?manufacturer=${this.inputVal.car_mark_id}`
      )
      this.carModels = res.data.results
    },
    async updateApplication() {
      const res = await this.$http.patch(
        `admin/applications/${this.inputVal.id}/`,
        this.inputVal
      )
      this.inputVal = res.data
    },
    async getCarPhotos() {
      const res = await this.$http.get(
        `admin/car-photos/?application=${this.inputVal.id}`
      )
      this.carPhotos = res.data.results
    },
    async openUpload() {
      document.getElementById('carPhotoFile').click()
    },
    async uploadPhoto() {
      const formData = new FormData()
      formData.append('photo_type', 0)
      formData.append('photo', this.carPhoto)
      formData.append('application', this.inputVal.id)
      await this.$http({
        method: 'POST',
        url: 'applications/car-photo/create/',
        data: formData,
        config: { headers: { 'content-type': 'multipart/form-data' } },
      })
      await this.getCarPhotos()
    },
    showUpload() {
      document.getElementById('carFileUpload').click()
    },
    rotatePhoto(index, degrees) {
      this.carPhotos[index].rotation += degrees
    },
    getImageStyles(rotation) {
      const width = rotation % 180 === 0 || rotation === 0 ? '500px' : 'auto'
      const height = rotation % 180 === 0 || rotation === 0 ? 'auto' : '500px'

      return {
        transform: `rotate(${rotation}deg)`,
        width,
        height,
      }
    },
    nextSlide() {
      this.$refs.swiperAuto.$swiper.slideNext()
    },
    prevSlide() {
      this.$refs.swiperAuto.$swiper.slidePrev()
    },
  },
}
</script>
